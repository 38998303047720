<template>
  <div class="v1payment">
    <van-nav-bar
      title="确认订单"
      left-arrow
      @click-left="onClickLeft"
      @click-right="goHome"
      right-text="返回首页"
    />
    <!-- 未登录 -->
    <div class="logout" v-if="!islogin">
      <van-form @submit="onLogin" v-if="false">
        <van-cell center title-class="agent_title" value-class="agent_value" v-if="false">
          <template #title>
            推荐人
          </template>
          <template #default>
            <van-image
              width="40"
              height="40"
              style="margin-right: 10px;vertical-align: middle;"
              lazy-load
              round
              :src="agentInfo.avatar"
            />
            <span style="display:inline-block;vertical-align: middle;">{{agentInfo.nickname}}</span>
          </template>
        </van-cell>
        <van-field
          ref="mobile"
          v-model="mobile"
          name="mobile"
          label="手机号"
          placeholder="手机号"
          @blur="checkMobile"
          :rules="[{ required: true, message: '请填写手机号' }]"
        />
        <van-field
          v-model="code"
          type="text"
          name="code"
          label="验证码"
          placeholder="验证码"
          :rules="[{ required: true, message: '请填写验证码' }]"
        >
          <template #button>
            <van-count-down v-show="!showTxt" :time="time" @finish="finish" ref="daojishi">
              <template v-slot="timeData">
                <span class="seconds">{{ timeData.seconds }}后重新发送</span>
              </template>
            </van-count-down>
            <van-button
              v-show="showTxt"
              type="primary"
              native-type="button"
              size="mini"
              :disabled="btnDisabled"
              @click="forgetpwd"
            >发送验证码</van-button>
          </template>
        </van-field>
        <van-field
          v-if="false"
          v-model="agentCode"
          name="agentCode"
          label="邀请码"
          :disabled="agentInfo.agent_code ? true : false"
          placeholder="邀请码"
        />
        <div style="margin: 16px;">
          <van-button round block type="danger" native-type="submit">
            确定
          </van-button>
        </div>
      </van-form>
      <div class="login_tishi">您还未登录，请点击登陆按钮进行登录后再购买</div>
      <van-button @click="gologin" type="primary">点击登陆</van-button>
    </div>
    <!-- 收货地址信息 -->
    <div class="postage_address" v-if="goodstype == 2 || goodstype == 3 ? false : true">
      <van-cell :border="false" :data-login="islogin" center v-if="islogin && address.name">
        <template #icon>
          <van-icon @click="shibieAddress" class="address_icon" name="location-o"></van-icon>
        </template>
        <template #right-icon>
          <van-icon @click="addAddress" class="check_address" name="arrow"></van-icon>
        </template>
        <template #title>
          <div @click="shibieAddress" class="van-multi-ellipsis--l2">
            <div class="name">{{ address.name }} {{ address.mobile }}</div>
            <div
              class="area"
            >{{ address.province }} {{ address.city }} {{ address.area }} {{ address.street }}</div>
          </div>
        </template>
      </van-cell>
      <!-- 没有收货地址的时候 -->
      <van-cell :border="false" :data-login="islogin" center v-if="!islogin || !address.name">
        <template #icon>
          <van-icon @click="shibieAddress" class="address_icon add" name="add-square"></van-icon>
        </template>
        <template #right-icon>
          <van-icon @click="addAddress" class="check_address" name="arrow"></van-icon>
        </template>
        <template #title>
          <div @click="shibieAddress" class="van-multi-ellipsis--l2">添加收货人</div>
        </template>
      </van-cell>
    </div>
    <!-- 虚拟商品显示手机号 -->
    <div class="postage_address" v-if="goodstype == 2 || goodstype == 3 ? true : false">
      <van-field v-model="mobile" type="tel" ref="mobileInput" :error="mobileError" label="手机号" placeholder="请输入手机号" />
    </div>
    <div class="goods_main">
      <div class="goods_list" v-if="goods && goods.length > 0">
        <div class="goods_list_detail" v-for="(val, key) in goods" :key="key">
          <van-card
            v-for="(item, index) in val.goods_info"
            :key="index"
            :price="item.marketprice"
            :title="strId + item.goodstitle"
            :tag="item.meihuo == 1 ? '暂无库存' : ''"
            @click-thumb="details(item.goodsid)"
          >
            <template #desc>
              {{item.optionname ? item.optionname : ''}}
              <van-tag color="#9168ed" text-color="#fff" v-if="item.yushouTime">{{item.yushouTime}}</van-tag>
            </template>
            <template #thumb>
              <van-image :src="item.goodsthumb" lazy-load>
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
              </van-image>
            </template>
            <template #num>
              <van-stepper
                v-model="item.num"
                :name="item.optionid"
                min="1"
                :max="item.stock"
                @change="numOnChange"
              />
            </template>
          </van-card>
          <div class="goods_set">
            <van-field
              :border="false"
              input-align="right"
              v-model="val.message"
              @input="messageInput($event, key)"
              label="买家留言"
              placeholder="建议留言前先与商家沟通确认"
            />
            <van-cell v-if="goodstype == 2 || goodstype == 3 ? false : true" :border="false" title="运费">
              <template #default>&yen;{{ val.totalYunFei.toFixed(2) }}</template>
            </van-cell>
            <van-cell v-if="(val.deductPrice && parseFloat(val.deductPrice) > 0)" :border="false" title="满减">
              <template #default><span class="red">&minus;&yen;{{ val.deductPrice }}</span></template>
            </van-cell>
            <div class="kongjia_tupian" v-if="val.is_kongjia == 1">
              <van-field center label="产品控价,上传老顾客截图,格式可咨询客服">
                <template #input>
                  <van-uploader v-model="uploadpic[key]" :name="key" max-count="4" :after-read="afterRead" :before-read="beforeRead" :before-delete="beforeDelete" />
                </template>
                <template #right-icon>
                  <van-icon name="arrow" />
                </template>
              </van-field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="kongjia_tupian" v-if="iskongjia">
      <van-field center label="产品控价,上传老顾客截图,格式可咨询客服">
        <template #input>
          <van-uploader v-model="uploadpic" max-count="1" :after-read="afterRead" :before-read="beforeRead" :before-delete="beforeDelete" />
        </template>
        <template #right-icon>
          <van-icon name="arrow" />
        </template>
      </van-field>
    </div>
    <div class="order_total">
      <van-cell-group :border="false">
        <van-cell :border="false" title="商品金额">
          <template #default>&yen;{{ goodsPrice.toFixed(2) }}</template>
        </van-cell>
        <van-cell v-if="goodstype == 2 || goodstype == 3 ? false : true" :border="false" title="运费">
          <template #default>&yen;{{ postPrice.toFixed(2) }}</template>
        </van-cell>
        <van-cell title="优惠">
          <template #default>
            <span class="red">&minus;&yen;{{ discountprice.toFixed(2) }}</span>
          </template>
        </van-cell>
        <van-cell title="满减" v-if="deductPrice && deductPrice > 0">
          <template #default>
            <span class="red">&minus;&yen;{{ deductPrice.toFixed(2) }}</span>
          </template>
        </van-cell>
        <van-cell>
          <template #default>
            合计：
            <span class="red">&yen;{{ totalPrice.toFixed(2) }}</span>
          </template>
        </van-cell>
      </van-cell-group>
    </div>
    <van-submit-bar
      :price="totalPrice * 100"
      button-text="提交订单"
      :disabled="islogin && !noxiadan ? false : true"
      :loading="!loadingbtn"
      :tip="noxiadanText"
      @submit="onSubmit"
    >
    </van-submit-bar>

    <transition name="van-slide-right"> 
      <div class="demo_cont" v-show="showshibieAddress">
        <van-form @submit="onSaveAddress" class="demo-animate-block">
          <van-icon name="close" size="26" color="#fff" @click="showshibieAddress = false" style="position: absolute; top: -30px;right: 0px;"></van-icon>
          <van-field
            name="conName"
            v-model="conName"
            label="姓名"
            placeholder="收货人姓名"
            :rules="[{ required: true, message: '请输入收货人姓名' }]"
          />
          <van-field
            name="conMobile"
            v-model="conMobile"
            label="电话"
            type="tel"
            placeholder="收货人手机号"
            :rules="[{ validator, message: '请输入收货人手机号' }]"
          />
          <van-field
            readonly
            clickable
            name="area"
            v-model="area"
            label="地区"
            placeholder="点击选择省市区"
            @click="showArea = true"
            :rules="[{ valiarea, message: '请选择省市区' }]"
          />
          <van-field
            name="street"
            v-model="street"
            label="详细地址"
            placeholder="街道门牌、楼层房间号等信息"
            :rules="[{ required: true, message: '请输入详细地址' }]"
          />
          <div class="address_shibie">
            <van-field
              ref="addressStr"
              v-model="addressStr"
              rows="3"
              type="textarea"
              placeholder="请输入地址信息 可自动识别"
            />
            <van-button size="small" plain type="primary" native-type="button" hairline @click="parseAddress">识别</van-button>
          </div>
          <van-popup v-model="showArea" position="bottom">
            <van-area
              :value="areaCode"
              :area-list="areaList"
              @confirm="onConfirm"
              @cancel="showArea = false"
            />
          </van-popup>
          <div style="margin: 16px;">
            <van-button round block type="info" :loading="saveAddBtn" :native-type="!saveAddBtn ? 'submit' : 'button'">
              保存
            </van-button>
          </div>
        </van-form>
      </div>
    </transition>
  </div>
</template>
<script>
import areaList from "@/components/area.js";
import SmartAddress from 'react-smart-address';
export default {
  name: "V1Payment",
  filters: {
    currencyFormat: function(num) {
      if (num === 0) {
        return "0.00";
      } else {
        num = num + "";
        if (num.length == 1) {
          num = "00" + num;
        }
        if (num.length == 2) {
          num = "0" + num;
        }
        let start = 0;
        start = num.length - 2;
        return num.slice(0, start) + "." + num.slice(start);
      }
    },
    dateFormat: function(value) {
      let date = null;
      let y = null;
      date = new Date(value);
      y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      /*
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      */
      return y + "/" + MM + "/" + d; // + ' ' + h + ':' + m + ':' + s;
    }
  },
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      user: {},
      goods: [],
      req: null, // 数据接收值
      islogin: localStorage.getItem("DK_ISLOGIN") ? true : false,
      address: {
        name: "",
        mobile: "",
        province: "",
        city: "",
        county: "",
        area: "",
        street: "",
        areaCode: "",
        id: ""
      }, // 收货地址
      option: {},
      spec1: {},
      spec2: {},
      openid: "",
      totalPrice: 0, // 订单金额
      goodsPrice: 0, // 商品金额
      postPrice: 0, // 运费金额
      mobile: "", // 手机号
      code: "", // 验证码
      showTxt: false, // 是否显示发送验证码按钮
      time: -1, // 倒计时时间
      btnDisabled: true,
      uploadpic: [],
      kongjiapic: [], // 控价图片链接地址
      iskongjia: false, // 默认隐藏控价
      discountprice: 0, // 优惠金额
      shareid: 0,
      merchid: 0,
      noxiadan: false, // 默认能下单
      noxiadanText: "",
      orderid: "", // 订单id
      ordersn: "", // 订单编号
      loadingbtn: false,
      showshibieAddress: false, // 地址识别弹框
      areaList: areaList,
      showArea: false, // 默认不显示收货地址
      area: "", // 省市县
      areaCode: "", // 省市县
      street: "", // 街道地址 or 识别出来的详细地址
      conName: "", // 姓名 or 识别出来的姓名
      conMobile: "", // 手机号 or 识别出来的手机号
      addressStr: "", // 需要识别的字符串
      province: "", // 识别出来的省
      city: "", // 识别出来的市
      county: "", // 识别出来的区县
      agentInfo: {}, // 上级信息
      agentCode: "", // 上级邀请码
      strId: "",
      messageArr: [], // 留言信息
      kongjiaArr: [], // 控价信息
      newopenid: null, // 合单支付时的openID
      iswx: false, 
      errorText: "",
      goodstype: 0, // 商品类型：1实物， 2虚拟产品
      mobileError: false, // 虚拟产品输入手机号有误时内容标红
      deductPrice: 0, // 总满减额
      saveAddBtn: false, // 保存地址
    };
  },
  activated() {
    this.islogin = localStorage.getItem("DK_ISLOGIN") ? true : false;
    if (localStorage.getItem("DK_DATA")) {
      this.req = localStorage.getItem("DK_DATA");
      this.req = this.$qs.parse(this.req);
    }

    if (this.islogin && localStorage.getItem("DK_ADDRESS")) {
      this.address = localStorage.getItem("DK_ADDRESS");
      let address = null;
      address = JSON.parse(this.address);
      console.log(address, "address");
      if(address.province && (address.province + "").trim()){
        this.address = JSON.parse(this.address);
      }
    }
    if (window.isWeixin()) {
      this.iswx = true;  // 在微信中打开
    }else{
      this.iswx = false; // 不在微信中打开
    }

    this.openid = localStorage.getItem("DK_OPENID");
    
    this.newopenid = localStorage.getItem("DK_NEW_OPENID") ? localStorage.getItem("DK_NEW_OPENID") : null;
    
    if (!this.newopenid && this.iswx) {
      this.code = this.$route.query.code ? this.$route.query.code : null;
      if (this.code) {
        this.getwxlog(this.code);
      }else {
        this.getopenid();
      }
    }
    
    this.loadingbtn = false;
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, "确认订单");

    if (!this.islogin && this.shareid) {
      this.getAgentInfo();
      setTimeout(() => {
        this.btnDisabled = true;
      }, 2);
    }
    this.strId = localStorage.getItem("DK_STRID");
    this.init();
  },
  deactivated() {
    localStorage.removeItem("DK_ADDRESS");
    this.address = {}
  },
  methods: {
    validator(val) {
      return /1\d{10}$/.test(val); // 校验函数返回 true 表示校验通过，false 表示不通过
    },
    gologin() {
      this.$router.push({ name: "Login", query: { shareid: this.shareid, merchid: this.merchid } });
    },
    valiarea(val) {
      let area = false;
      let areaArr = [];
      if (val) {
        if (val.indexOf("/") !== -1) {
          areaArr = val.split("/");
          if (areaArr.length >= 3) {
            area = true;
          }
        }
      }
      return area;
    },
    init() {
      this.totalPrice = 0; // 订单金额
      this.goodsPrice = 0; // 商品金额
      this.postPrice = 0; // 运费金额

      // 获取产品信息
      if (this.req) {
        this.getgoodsinfo();
      }
      // 根据openid 获取用户信息
      // const memberid = localStorage.getItem("DK_MEMBERID");
      // if(memberid==69041 || memberid==123274 || memberid==null) {
      //   console.log("ddd");
      // }else {
      //   const wxinfo = this.$qs.parse(localStorage.getItem("DK_WXINFO"));
      //   if (wxinfo && wxinfo.openid ) {
      //     this.getopenidInfo(wxinfo.openid);
      //   }
      // }
    },

    /**
     * 微信code值
     */
    getopenid() {
      let _that = null;
      _that = this;
      /* 以下下是获取openID 以及判断用户是否在微信中打开页面 */
      let openid = null;
      openid = localStorage.getItem("DK_NEW_OPENID") ? localStorage.getItem("DK_NEW_OPENID") : false;

      /* 在微信中打开页面 */
      if (!openid && this.iswx) { 
        const wxAuthUrl = 
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx65fad56bebb47a1e&redirect_uri=" + encodeURIComponent(window.location.href) + "&response_type=code&scope=snsapi_base&state=123#wechat_redirect";

          // 传到后台得到openid, 其中access_token 2小时过期
          if (_that.code == null || _that.code === "") {
            window.location.href = wxAuthUrl; // [自刷新当前页]
          }
      } else {
        // localStorage存在openid || 本页面不在公众号中打开
        console.log("localStorage存在openid || 本页面不在公众号中打开");
      }
    },

    /**
     * 获取微信openID
     */
    getwxlog(code) {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/wetch/wx_new_oauth2",
          _that.$qs.stringify({
            code: code
          })
        )
        .then(function(response) {
          if (response.data) {
            localStorage.setItem("DK_NEW_OPENID", response.data); // 获得用户的openid
            /* 保存openID */
            // _that.saveOpenid();
            _that.goPay();
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
    // 检查手机号绑定的会员id是否绑定openid
    checkMobile() {
      let _that = null;
      _that = this;
      if (_that.mobile) {
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        _that.$axios
          .post(
            _that.$store.state.domain + "web/user/check_mobile",
            _that.$qs.stringify({
              mobile: _that.mobile
            })
          )
          .then(response => {
            console.log(response);
            if (response.data.code == 100000) {
              _that.btnDisabled = false;
            }else {
              _that.$notify({
                type: "warning",
                message: response.data.msg ? response.data.msg : "手机号已使用，请更换手机号",
                duration: 1500
              })
              _that.btnDisabled = true;
            }
          })
          .catch(error => {
            console.log(error);
          })
      }
        
    },
    // 根据openid 获取用户信息
    getopenidInfo(openid) {
      let _that = null;
      _that = this;

      _that.$axios
        .post(
          _that.$store.state.domain + "web/user/userInfoByOpenid",
          _that.$qs.stringify({
            openid: openid
          })
        )
        .then(res => {
          if (res.data.code == 100000) {
            if (res.data.user.mobile) {
              localStorage.setItem("DK_UID",res.data.data);
              localStorage.setItem("DK_ISLOGIN",true);
              localStorage.setItem("DK_OPENID",openid);
              localStorage.setItem("DK_MEMBERID", res.data.user.id)
              localStorage.setItem("DK_STRID",res.data.user.strId);
              _that.strId = res.data.user.strId;
              _that.islogin = true;
              _that.openidBindUid(); // 绑定用户id
            }
          }
        })
    },
    // 获取产品信息
    getgoodsinfo() {
      let _that = null;
      _that = this;
      let DKUID = "";
      DKUID = localStorage.getItem("DK_UID");
      if (_that.req) {
        if (typeof _that.req === String) {
          _that.req = _that.$qs.parse(_that.req);
        }
      }

      let message = localStorage.getItem("message");
      message = this.$qs.parse(message);

      let kongjiapic = localStorage.getItem("kongjiapic");
      kongjiapic = kongjiapic ? JSON.parse(kongjiapic) : [];
      _that.uploadpic = [];

      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios.defaults.headers.common["Authorization"] = DKUID;
      _that.$axios
        .post(
          _that.$store.state.domain + "web/orderv1/confirm",
          _that.$qs.stringify({
            goods: _that.req,
            addressid: _that.address.id,
            "merchid_fake": _that.merchid,
          })
        )
        .then(response => {
          _that.loadingbtn = true;
          if (response && response.data && response.data.code == 100000) {
            _that.goods = response.data.data.goods_info;
            console.log(_that.goods);
            _that.goodstype = _that.goods[0].goods_info[0].goods_type && parseInt(_that.goods[0].goods_info[0].goods_type) > 0 ? parseInt(_that.goods[0].goods_info[0].goods_type) : 1;
            _that.goodsPrice = parseFloat(response.data.data.marketPrice);
            _that.totalPrice = parseFloat(response.data.data.payPrice);
            _that.postPrice = parseFloat(response.data.data.totalYunFei);
            _that.discountprice = parseFloat(response.data.data.discountprice);
            _that.deductPrice = parseFloat(response.data.data.totalDeduct);
            _that.goods.forEach((item, index) => {
              if (item.goods_info) {
                item.goods_info.forEach((val, key) => {
                  if (val.marketprice > 0) {
                    _that.goods[index].goods_info[key].marketprice = val.marketprice.toFixed(2); 
                  }
                })
                if (message[index]) {
                  _that.goods[index].message = message[index]; 
                  _that.messageArr[index] = message[index];
                }
                if (kongjiapic && kongjiapic.length > 0) {
                  if (kongjiapic[index]) {
                    _that.goods[index].kongjiapic = kongjiapic[index]; 
                    _that.kongjiaArr[index] = kongjiapic[index];
                    if (kongjiapic[index].length > 0) {
                      kongjiapic[index].forEach(val => {
                        if (_that.uploadpic[index] && _that.uploadpic[index].length > 0) {
                          _that.uploadpic[index].push({url:val, isImage: true});
                        }else {
                          _that.uploadpic.push([{url:val, isImage: true}])
                        }
                      })
                    }
                  }else {
                    _that.goods[index].kongjiapic = [];
                    _that.kongjiaArr[index] = [];
                    _that.uploadpic[index] = [];
                  }
                }else {
                  _that.goods[index].kongjiapic = [];
                  _that.kongjiaArr[index] = [];
                  _that.uploadpic[index] = [];
                }
              }
            });
            console.log(_that.goods, _that.kongjiaArr, "goods_get_info");
            // _that.getOrderPrice();
            _that.noxiadan = false;
            _that.noxiadanText = "";
          }else {
            _that.noxiadan = true;
            _that.noxiadanText = response.data.msg ? response.data.msg : "";
          }
          if (response && response.data && response.data.userinfo) {
            _that.user = response.data.userinfo;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    // 回首页
    goHome() {
      let _that = null;
      _that = this;
      _that.$router.replace({name: "Home", query: { shareid: _that.shareid, merchid: _that.merchid, _t: 3 } });
    },
    // 返回上一页
    onClickLeft() {
      localStorage.removeItem("DK_ADDRESS");
      localStorage.removeItem("DK_DATA");
      localStorage.removeItem("money");
      localStorage.removeItem("ordersn");
      localStorage.removeItem("orderid");
      this.$router.go(-1);
    },
    // 添加收货地址
    addAddress() {
      console.log(this.islogin);
      if (this.islogin) {
        this.$router.push({ name: "AddressList", query: { type: "orderAdd", shareid: this.shareid, merchid: this.merchid } });
      }
    },
    // 识别地址信息
    shibieAddress() {
      if (this.islogin) {
        this.showshibieAddress = true;
      }else {
        this.$toast("请登录后再添加地址")
      }
    },
    // 确认选择省市县
    onConfirm(val) {
      console.log(val);
      this.showArea = false;
      this.area = "";
      this.areaCode = 0;
      if (val) {
        if (val[0]) {
          this.province = val[0].name;
        }
        if (val[0]) {
          this.city = val[1].name;
        }
        if (val[0]) {
          this.county = val[2].name;
        }
        
        val.forEach((item) => {
          if (item.name && item.code) {
            this.area += item.name + "/";
            this.areaCode = item.code;
          }
        })
      }
      this.area = this.area.substr(0, (this.area.length - 1));
      console.log(this.area, this.areaCode);
    },
    // 保存识别地址
    onSaveAddress() {
      let _that = null;
      _that = this;
      if (!this.islogin) {
        this.$toast("请登录后再添加收货人");
        return false;
      }

      if (_that.conName) {
        _that.conName = _that.conName.trim();
      }else{
        _that.$toast("请填写收货人姓名");
        return false;
      }
      if (_that.conMobile) {
        _that.conMobile = _that.conMobile.trim();
      }else{
        _that.$toast("请填写手机号");
        return false;
      }
      if (_that.province) {
        _that.province = _that.province.trim();
      }else{
        _that.$toast("请选择省");
        return false;
      }
      if (_that.city) {
        _that.city = _that.city.trim();
      }else{
        _that.$toast("请选择城市");
        return false;
      }
      if (_that.county) {
        _that.county = _that.county.trim();
      }else{
        _that.$toast("请选择区县");
        return false;
      }
      if (_that.street) {
        _that.street = _that.street.trim();
      }else{
        _that.$toast("请填写详细地址");
        return false;
      }
      if (!_that.saveAddBtn) {
        _that.saveAddBtn = true;
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
        _that.$axios
          .post(
            _that.$store.state.domain + "web/address/add",
            _that.$qs.stringify({
              content: {
                name: _that.conName,
                tel: _that.conMobile,
                province: _that.province,
                city: _that.city,
                county: _that.county,
                addressDetail: _that.street,
                areaCode: _that.areaCode,
              }
            })
          )
          .then(res => {
            console.log(res);
            if (res.data.code == 100000) {
              _that.address.name = _that.conName;
              _that.address.mobile = _that.conMobile;
              _that.address.province = _that.province;
              _that.address.city = _that.city;
              _that.address.county = _that.county;
              _that.address.area = _that.county;
              _that.address.street = _that.street;
              _that.address.areaCode = _that.areaCode;
              _that.address.id = res.data.data;

              localStorage.setItem("DK_ADDRESS", _that.$qs.stringify(_that.address));

              _that.area = ""; // 省市县
              _that.areaCode = ""; // 省市县code
              _that.street = ""; // 街道地址 or 识别出来的详细地址
              _that.conName = ""; // 姓名 or 识别出来的姓名
              _that.conMobile = ""; // 手机号 or 识别出来的手机号
              _that.addressStr = ""; // 需要识别的字符串
              _that.province = ""; // 识别出来的省
              _that.city = ""; // 识别出来的市
              _that.county = ""; // 识别出来的区县
              _that.showshibieAddress = false;

              _that.getgoodsinfo();
              _that.saveAddBtn = false;
            }else {
              _that.$toast(res.data.msg ? res.data.msg : "保存失败")
            }
          })
          .catch(err => {
            console.log(err);
          })
      }
        
    },
    // 执行识别地址
    parseAddress() {
      let address = null;
      address = SmartAddress.smart(this.addressStr);

      let name = "";
      if (address.name) {
        name = address.name;
      }else {
        name = address.residueaddress;
      }
      if (address.countyCode) {
        this.areaCode = address.countyCode;
      }else {
        if (address.cityCode) {
          this.areaCode = address.cityCode;
        }else {
          if (address.provinceCode) {
            this.areaCode = address.provinceCode;
          }
        }
      }

      this.conName = name;
      this.conMobile = address.mobile;
      this.province = address.province;
      this.city = address.city;
      this.county = address.county;
      this.street = address.address;
      if (address.province || address.city || address.county) {
        this.area = this.province + "/" + this.city + "/" + this.county;
      }
      console.log(address);
    },
    // 改变产品数量
    numOnChange(num, name) {
      let optionid = null;
      optionid = name.name;
      // this.req = Array.from(this.req);
      const arr = Object.values(this.req);
      // console.log(num, name, this.req);

      arr.forEach((item, index) => {
        if (item.optionid == optionid) {
          arr[index].num = num;
        }
      })

      localStorage.setItem("DK_DATA", this.$qs.stringify(arr));
      this.getgoodsinfo();
      // this.goods[index].num = num;
      // this.getOrderPrice();
    },
    // 获取订单价格运费
    getOrderPrice() {
      let _that = null;
      _that = this;
      const goods = [];
      _that.goods.forEach(res => {
        goods.push({
          goodsid: res.goodsid,
          optionid: res.optionid,
          num: res.num
        });
      });
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      const DKUID = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers.common["Authorization"] = DKUID;
      _that.$axios
        .post(
          _that.$store.state.domain + "web/order/confirm",
          _that.$qs.stringify({
            goods: goods,
            addressid: _that.address.id,
            "merchid_fake": _that.merchid
          })
        )
        .then(response => {
          // _that.loadingbtn = true;
          if (response.data.code == 100000) {
            // _that.goodsPrice = parseFloat(response.data.data.marketPrice);
            // _that.totalPrice = parseFloat(response.data.data.payPrice);
            // _that.postPrice = parseFloat(response.data.data.totalYunFei);
            // _that.discountprice = parseFloat(response.data.data.discountprice);
            if (response.data.goods_info) {
              _that.goods.forEach((item, index) => {
                response.data.data.goods_info.forEach(val => {
                  if (val.goodsid == item.goodsid && val.optionid == item.optionid) {
                    _that.goods[index].price = val.marketprice
                    _that.goods[index].stock = val.stock
                    _that.goods[index].meihuo = val.meihuo
                  }
                })
              });
            }
            _that.noxiadan = false;
            _that.noxiadanText = "";
          }else {
            _that.noxiadan = true;
            _that.noxiadanText = response.data.msg;
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
    // 上传前
    beforeRead() {
      if (!this.islogin) {
        this.$toast("未登录")
        return false;
      }else{
        return true;
      }
    },
    // 控价截图上传
    afterRead(file, detail) {
      console.log(file, detail, "detail");
      let _that = null;
      _that = this;
      if (!this.islogin) {
        this.$toast("未登录")
        return false;
      }
      const fordata = new FormData();
      fordata.append('file',file.file);
      fordata.append('dir','image/order/kongjia/');
      fordata.append('merchid',_that.merchid);
      _that.$axios.defaults.headers["Content-Type"] =  "multipart/form-data";
      _that.$axios.post(
        "https://api.midbest.cn/admin/image/shangchuan",
        fordata
      ).then( response => {
        if (response.data && response.data.code == 100000) {
          if (!_that.goods[detail.name].kongjiapic) {
            _that.goods[detail.name].kongjiapic = [response.data.img_url]
          }else {
            _that.goods[detail.name].kongjiapic.push(response.data.img_url);
          }
          if (_that.kongjiaArr[detail.name] && _that.kongjiaArr[detail.name].length >= 0) {
            if (_that.kongjiaArr[detail.name].indexOf(response.data.img_url) < 0) {
              _that.kongjiaArr[detail.name].push(response.data.img_url);
            }
          }else {
            _that.kongjiaArr.push([response.data.img_url]);
          }
          localStorage.setItem("kongjiapic", JSON.stringify(_that.kongjiaArr));
        }else {
          _that.$toast(response.data.msg ? response.data.msg : "上传失败");
        }
      }).catch( error =>{
        console.log(error);
      })
    },
    // 删除截图
    beforeDelete(file, detail) {
      this.goods[detail.name].kongjiapic.splice(detail.index, 1);
      this.uploadpic[detail.name].splice(detail.index, 1);
      this.kongjiaArr[detail.name].splice(detail.index, 1);
      if (this.kongjiaArr && this.kongjiaArr.length > 0) {
        localStorage.setItem("kongjiapic", JSON.stringify(this.kongjiaArr));
      }else{
        localStorage.removeItem("kongjiapic");
      }
      
    },
    // 添加留言信息
    messageInput(val, index) {
      this.messageArr[index] = val;
      console.log(val,index,this.messageArr);
      if (this.messageArr) {
        localStorage.setItem("message", this.$qs.stringify(this.messageArr));
      }else{
        localStorage.removeItem("message");
      }
      
    },
    // 提交订单
    onSubmit() {
      let _that = null;
      _that = this;
      if (_that.goodstype == 2 || _that.goodstype == 3) {
        if (!_that.validator(_that.mobile)) {
          _that.$toast("请填写正确手机号");
          _that.mobileError = true;
          _that.$refs['mobileInput'].focus();
          return false;
        }
      } else {
        if (!this.address.id) {
          this.$toast("未填写收货地址");
          return false;
        }else if(!(this.address.name.trim())) {
          this.$toast("未填写收货人姓名");
          return false;
        }else if(!(this.address.mobile.trim())) {
          this.$toast("未填写收货人手机号");
          return false;
        }else if(!this.address.province || !this.address.city || !this.address.area) {
          this.$toast("请补全省市县");
          return false;
        }else if(!(this.address.street.trim())) {
          this.$toast("未填写详细地址");
          return false;
        }
      }
      console.log(_that.goodstype);


      const goods = [];
      _that.loadingbtn = false; // 提交等待中
      _that.goods.forEach(res => {
        if (res.goods_info) {
          res.goods_info.forEach(val => {
            goods.push({
              goodsid: val.goodsid,
              optionid: val.optionid,
              num: val.num,
              merchid: val.merchid,
              message: res.message,
              kongjiapic: encodeURIComponent(res.kongjiapic)
            });
          })
        }
      });

      if (_that.noxiadan) {
        _that.$toast({
          message: _that.noxiadanText,
        })
        return false;
      }
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      const DKUID = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers.common["Authorization"] = DKUID;
      _that.$axios
        .post(
          _that.$store.state.domain + "web/orderv1/add",
          // "http://mytest.com/web/orderv1/add",
          _that.$qs.stringify({
            goods: goods,
            addressid: _that.address.id,
            "merchid_fake": _that.merchid,
            openid: _that.newopenid, // 参与支付的openID
            mobile: _that.mobile // 虚拟产品必填
          })
        )
        .then(response => {
          console.log(response);    
          if (response.data.code == 100000) {
            let uid = 0;
            uid = response.data.data.mid;
            _that.uploadpic = [];
            localStorage.removeItem("message");
            localStorage.removeItem("kongjiapic");
            localStorage.setItem("ordersn", response.data.data.ordersn);
            localStorage.setItem("money", response.data.data.money);
            localStorage.setItem("orderid", response.data.data.oid);
            _that.address = {};
            _that.$router.replace({name: "CheckPayment", query: {shareid: _that.shareid, merchid: _that.merchid}})
          }else {
            _that.loadingbtn = true; // 提交等待中
            _that.$toast({
              message:response.data.msg
            });
          }
        })
        .catch(error => {
          console.log(error);
          _that.loadingbtn = true; // 提交等待中
        });
    },
    // 登陆or注册
    onLogin() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios.post(
        _that.$store.state.domain + "web/user/login",
        _that.$qs.stringify({
          tel: _that.mobile,
          code: _that.code,
          codetype: 2,
          wxinfo: localStorage.getItem("DK_WXINFO"),
          shareid: _that.shareid,
          openid: localStorage.getItem("DK_OPENID"),
          memberid: localStorage.getItem("DK_MEMBERID")
        })
      ).then(function(response){
        if (response.data && response.data.code == 100000) {
          localStorage.setItem("DK_UID",response.data.data)
          localStorage.setItem("DK_ISLOGIN",true)
          localStorage.setItem("DK_MEMBERID", response.data.user.id)
          localStorage.setItem("DK_STRID",response.data.user.strId);
          _that.strId = response.data.user.strId;
          _that.$notify({
            type:'success',
            message: response.data.msg?response.data.msg:'登陆成功'
          })
          _that.islogin = localStorage.getItem("DK_ISLOGIN") ? true : false;
          localStorage.removeItem("DK_WXINFO");
          _that.openidBindUid(); // 绑定用户id
        }else{
          _that.$notify({
            type:'warning',
            message: response.data && response.data.msg?response.data.msg:'登陆失败'
          })
        }
      }).catch(function(error){
        console.log(error);
      })
    },
    // 倒计时完成后执行
    finish() {
      this.btnDisabled = false;
      this.showTxt = true;
    },
    // 发送验证码
    forgetpwd() {
      if (this.mobile && this.validator(this.mobile)) {
        this.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        this.$axios.post(
          this.$store.state.domain + "web/user/send_sms",
          this.$qs.stringify({
            tel: this.mobile,
            type: 'login'
          })
        )
        this.$refs.daojishi.reset();
        this.time = 60 * 1000;
        this.btnDisabled = true;
        this.showTxt = false;
      }else{
        this.$refs.mobile.focus();
      }
    },
    // openid 和用户id绑定 
    openidBindUid() {
      let _that = null;
      _that = this;
      const uid = localStorage.getItem("DK_MEMBERID");
      const openid = localStorage.getItem("DK_OPENID");
      if (parseInt(uid) > 0 && openid) {
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        _that.$axios
          .post(
            _that.$store.state.domain + "web/pay/setopenid",
            _that.$qs.stringify({
              mid: parseInt(uid),
              openid: openid,
              type: "daka"
            })
          )
          .then(res => {
            console.log(res);
          })
          .catch(err => {
            console.log(err);
          })
      }
    },
    // 查询上级信息
    getAgentInfo() {
      let _that = null;
      _that = this;
      if (parseInt(_that.shareid) > 0) {
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        _that.$axios
          .post(
            _that.$store.state.domain + "web/user/getparentByMid",
            _that.$qs.stringify({
              id: _that.shareid
            })
          )
          .then(res => {
            console.log(res);
            if (res.data.code == 100000) {
              _that.agentInfo = res.data.data;
              _that.agentCode = res.data.data.agent_code ? res.data.data.agent_code : "";
            }
          })
          .catch(error => {
            console.log(error);
          })
      }
    },
    // 进入详情页
    details(id) {
      console.log(id);
      this.$router.push({name: "Details", query: {id: id, shareid: this.shareid, merchid: this.merchid, _t: 3 } });
    }
  }
};
</script>
<style lang="less">
.v1payment {
  background-color: #ededed;
  position: relative;
  z-index: 100;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    .van-icon {
      color: #333;
    }
    .van-nav-bar__text{
      color: #333;
    }
  }
  // 收货地址信息
  .postage_address {
    position: relative;
    background-color: #fff;
    &::after {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 2px;
      background: -webkit-repeating-linear-gradient(
        135deg,
        #ff6c6c 0,
        #ff6c6c 20%,
        transparent 0,
        transparent 25%,
        #1989fa 0,
        #1989fa 45%,
        transparent 0,
        transparent 50%
      );
      background: repeating-linear-gradient(
        -45deg,
        #ff6c6c 0,
        #ff6c6c 20%,
        transparent 0,
        transparent 25%,
        #1989fa 0,
        #1989fa 45%,
        transparent 0,
        transparent 50%
      );
      background-size: 80px;
      content: "";
    }
    .van-cell {
      padding: 10px 16px;
      .van-cell__title {
        flex: 1;
        text-align: left;
      }
      .address_icon {
        font-size: 18px;
        padding-right: 16px;
      }
      .add {
        font-size: 40px;
        color: #1989fa;
      }
      .check_address {
        font-size: 18px;
        height: 40px;
        line-height: 40px;
        width: 40px;
      }
      .name {
        font-weight: bold;
      }
      .area {
        line-height: 20px;
        font-size: 12px;
        color: #666;
      }
    }
    // 虚拟产品手机号
    .van-field {
      padding: 16px 20px;
      line-height: 30px;
      font-size: 16px;
      .van-field__label {
        max-width: 70px;
      }
      .van-field__value {
        flex: 1;
      }
    }
  }
  // 产品列表
  .goods_main {
    padding: 10px 0 0;
    .goods_list_detail{
      border-radius: 10px;
      background-color: #fff;
      margin-bottom: 10px;
      overflow: hidden;
      .van-card {
        border-radius: 10px;
        overflow: hidden;
        text-align: left;
        background-color: #fff;
        margin-top: 0px;
        .van-card__thumb {
          overflow: hidden;
        }
        .van-card__price {
          color: #f22f31;
        }
        .van-card__footer {
          margin-top: 10px;
          .van-cell {
            padding: 5px 0;
            line-height: 30px;
            .van-field__label {
              width: 35px;
            }
            .van-cell__value {
              flex: 1;
            }
          }
        }
      }
      .goods_set {
        padding-top: 10px;
        .van-cell {
          .van-cell__title {
            width: 60px;
            text-align: left;
          }
          .van-cell__value {
            flex: 1;
            text-align: right;
          }
        }
        // 控价传图
        .kongjia_tupian {
          overflow: hidden;
          padding: 10px 16px;
          background-color: #fff;
          border-radius: 10px;
          .van-cell {
            padding: 0;
            .van-cell__title {
              flex: 1;
              color: #333;
            }
            .van-cell__value {
              flex: none;
              .van-uploader__upload {
                width: 50px;
                height: 50px;
                margin: 0;
              }
              .van-uploader__preview{
                margin: 0 5px 5px;
              }
              .van-uploader__preview-image {
                width: 50px;
                height: 50px;
                margin: 0;
              }
              .van-uploader__file {
                width: 50px;
                height: 50px;
              }
            }
          }
        }
      }
    }
  }
  
  // 订单总计
  .order_total {
    padding-bottom: 60px;
    .van-cell-group {
      background-color: #fff;
      border-radius: 10px;
      overflow: hidden;
      .van-cell {
        font-weight: bold;
        .van-cell__value {
          flex: 1;
          text-align: right;
          color: #353535;
        }
      }
    }
  }
  // 未登录输入手机号密码
  .logout {
    background-color: #fff;
    overflow: hidden;
    margin-bottom: 10px;
    padding: 20px 0;
    .login_tishi {
      font-size: 14px;
      font-size: #333;
      margin-bottom: 10px;
    }
    .van-button {
      height: 33px;
    }
    .van-field__label {
      width: 50px;
    }
    .van-cell__value{
      flex: 1;
    }
    .van-count-down{
      background-color: #bbb;
      padding: 1px 6px;
      .seconds{
        color: #ffffff;
        font-size: 12px;
      }
    }
    .agent_title {
      width: 60px;
      margin-right: 12px;
      text-align: left;
    }
    .agent_value {
      text-align: left;
    }
  }
  .demo_cont {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;

    .demo-animate-block {
      position: absolute;
      top: 10%;
      left: 10%;
      width: 80%;
      background-color: #fff;
      border-radius: 3px;
      padding: 10px;
      margin: -10px 0 0 -10px;
      .van-cell__title{
        width: 60px;
      }
      .van-cell__value{
        flex: 1;
      }
      .address_shibie {
        margin-top: 10px;
        overflow: hidden;
        background-color: #fff;
        padding: 10px 0;
        text-align: right;
        .van-button{
          margin-top: 10px;
          margin-right: 16px;
          padding: 0 20px;
        }
        .van-button--hairline::after{
          border-radius: 10px;
        }
      }
    }
  }
  .red {
    color: #e2280a;
  }
}
</style>
